import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";

export default function Private({ children }: any) {

  const { signed, loading } = useContext(AuthContext);

  if (loading) {
    return <div></div>
  }

  if (!signed) {
    return <Navigate to='/admin' />
  }

  return children;
}
