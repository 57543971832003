import { useState, createContext, useEffect, ReactNode } from 'react';
import firebase from '../services/firebaseConnect';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

type AuthContextData = {
  user: UserProps | null;
  signed: boolean;
  loadingAuth: boolean;
  loading: boolean;
  signIn: (email: string, pass: string) => Promise<void>;
  signOut: () => Promise<void>;
  sendPassEmail: (email: string) => void;
  signUp: (email: string) => Promise<void>;
};

type UserProps = {
  uid: string;
  email: string;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();

  const [user, setUser] = useState<UserProps | null>(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const signed = !!user;

  useEffect(() => {
    (async () => {
      const userStorage = await localStorage.getItem('@dooropener:auth');

      if (userStorage) {
        setUser(JSON.parse(userStorage));
        setLoading(false);
      }

      setLoading(false);
    })();
  }, []);

  const signUp = async (email: string) => {
    setLoadingAuth(true);

    const currentEmail = email.toLowerCase();

    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(currentEmail, 'admin@Dellforms')
        .then(() => {
          toast.success(`${currentEmail} | Cadastrado com sucesso!`);
        });
    } catch (error) {
      console.log('REGISTER: ', error);
      toast.error('Tente novamente em alguns minutos!');
    } finally {
      setLoadingAuth(false);
    }
  };

  const signIn = async (email: string, pass: string) => {
    // if (email.indexOf('admin@') === -1) return;

    setLoadingAuth(true);

    const currentEmail = email.toLowerCase();

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(currentEmail, pass)
        .then(async (current) => {
          const data: UserProps = {
            uid: current.user?.uid as string,
            email: currentEmail,
          };

          setUser(data);
          storageUser(data);
          navigate('/dashboard');
        });
    } catch (error) {
      console.log('ERROR: ', error);
      toast.error('Verifique suas credenciais e tente novamente!');
    } finally {
      setLoadingAuth(false);
    }
  };

  const storageUser = (data: UserProps) => {
    localStorage.setItem('@dooropener:auth', JSON.stringify(data));
  };

  const signOut = async () => {
    await firebase
      .auth()
      .signOut()
      .then(async () => {
        await localStorage.removeItem('@dooropener:auth');
        navigate('/admin');
      });
  };

  const sendPassEmail = (email: string) => {
    setLoadingAuth(true);

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(
          'Um email de redefinição de senha foi enviado para você!'
        );
        setLoadingAuth(false);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
        toast.error(
          'Verifique o e-mail informado e tente novamente em alguns minutos!'
        );
        setLoadingAuth(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signed,
        loadingAuth,
        loading,
        signIn,
        signOut,
        sendPassEmail,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
