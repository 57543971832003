import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../screens/Home';
import Admin from '../screens/Admin';
import Dashboard from '../screens/Dashboard';

import Private from './Private';

export default function RoutesApp() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/dashboard' element={<Private><Dashboard /></Private>} />
    </Routes>
  )
}
