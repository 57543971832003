import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAVkZfunSkrILPomlfbBBSmCt51sjUhTLQ',
  authDomain: 'dooropener-forms.firebaseapp.com',
  databaseURL: 'https://dooropener-forms-default-rtdb.firebaseio.com',
  projectId: 'dooropener-forms',
  storageBucket: 'dooropener-forms.appspot.com',
  messagingSenderId: '982331635984',
  appId: '1:982331635984:web:091cc01b896e40675fcd95',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
