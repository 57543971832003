import React, { useEffect, useState } from 'react';
import firebase from '../../services/firebaseConnect';
import * as xlsx from 'xlsx';
import { Calendar } from '../../components/Calendar';

// import { toast } from "react-hot-toast";
import Header from '../../components/Header';

import { Container } from './styles';
import { Button, Card } from 'react-bootstrap';
import OnlineEvent from './OnlineEvent';
import PersonEvent from './PersonEvent';
import { OnlineEventData, PresencialEventData } from '../Home';

type AllEventsData = [data: OnlineEventData | PresencialEventData];

export default function Dashboard() {
  const [cardSelected, setCardSelected] = useState<
    'online' | 'presencial' | ''
  >('');
  const [isHiddenCards, setIsHiddenCards] = useState(false);

  const [allEventsOnline, setAllEventsOnline] = useState<AllEventsData[] | []>(
    []
  );
  //
  const [allEventsInPerson, setAllEventsInPerson] = useState<any[] | []>([]);
  //
  const [allEvents, setAllEvents] = useState<any[] | []>([]);
  const [successOnline, setSuccessOnline] = useState(false);
  const [successInPerson, setSuccessInPerson] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getAllEventsOnline();
    })();
  }, []);

  const getAllEventsOnline = () => {
    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('online_events')
        .get()
        .then((snapshot) => {
          const arrEvents = [] as any[];
          const arrEventsAll = [] as OnlineEventData[];

          snapshot.forEach((document) => {
            const newEventAll = {
              id: document.id,
              accountId: document.data().accountId,
              outherDates: document.data().outherDates,
              dellName: document.data().dellName,
              dellEmail: document.data().dellEmail,
              dellPhone: document.data().dellPhone,
              companyInvited: document.data().companyInvited,
              dateSelected: document.data().dateSelected,
              eventType: document.data().eventType,
              totalPersonsOnline: document.data().totalPersonsOnline,
              totalDellPersonsOnline: document.data().totalDellPersonsOnline,
              doorOpenerSugestion: document.data().doorOpenerSugestion,
              createdAt: document.data().createdAt,
              status: document.data().status,
            } as OnlineEventData;

            const newEvent = {
              id: document.id,
              account_id: document.data().accountId,
              nome_solicitante_dell: document.data().dellName,
              email_solicitante_dell: document.data().dellEmail,
              telefone_solicitante_dell: document.data().dellPhone,
              empresa_convidada: document.data().companyInvited,
              data_selecionada: document.data().dateSelected,
              outras_datas: document.data().outherDates,
              tipo_evento: document.data().eventType,
              quantidade_pessoas: document.data().totalPersonsOnline,
              quantidade_pessoas_dell: document.data().totalDellPersonsOnline,
              observacao_sugestao_door_opener:
                document.data().doorOpenerSugestion,
              createdAt: document.data().createdAt,
              status: document.data().status,
            };

            arrEvents.push(newEvent);
            arrEventsAll.push(newEventAll);
            setSuccessOnline(true);
          });

          setAllEventsOnline(arrEvents);
          // setAllEvents(arrEventsAll);
          getAllEventsInPerson(arrEventsAll);
        });
    } catch (error) {
      console.log('ERROR ONLINE: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getAllEventsInPerson = (onlineEvents: any) => {
    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('person_events')
        .get()
        .then((snapshot) => {
          const arrEvents = [] as any[];
          const arrEventsAll = [] as PresencialEventData[];

          snapshot.forEach((document) => {
            const newEventAll = {
              id: document.id,
              accountId: document.data().accountId,
              dellName: document.data().dellName,
              dellEmail: document.data().dellEmail,
              dellPhone: document.data().dellPhone,
              companyInvited: document.data().companyInvited,
              dateSelected: document.data().dateSelected,
              outherDates: document.data().outherDates,
              eventType: document.data().eventType,
              cityInPerson: document.data().cityInPerson,
              quantityPersonsInPerson: document.data().quantityPersonsInPerson,
              coffeeIsPerson: document.data().coffeeIsPerson,
              lunchInPerson: document.data().lunchInPerson,
              // lunchPlaceInPerson: document.data().lunchPlaceInPerson,
              // lunchFabricInPerson: document.data().lunchFabricInPerson,
              // salad: document.data().salad,
              // mainCourse: document.data().mainCourse,
              // mainCoursePlus: document.data().mainCoursePlus,
              // followUp: document.data().followUp,
              // candy: document.data().candy,
              // fruit: document.data().fruit,
              // drink: document.data().drink,
              restaurantInPerson: document.data().restaurantInPerson,
              meetingPointInPerson: document.data().meetingPointInPerson,
              meetingPointOutherInPerson:
                document.data().meetingPointOutherInPerson,
              doorOpenerSugestion: document.data().doorOpenerSugestion,
              createdAt: document.data().createdAt,
              status: document.data().status,
            } as PresencialEventData;

            const newEvent = {
              id: document.id,
              account_id: document.data().accountId,
              nome_solicitante_dell: document.data().dellName,
              email_solicitante_dell: document.data().dellEmail,
              telefone_solicitante_dell: document.data().dellPhone,
              empresa_convidada: document.data().companyInvited,
              data_selecionada: document.data().dateSelected,
              outras_datas: document.data().outherDates,
              tipo_evento: document.data().eventType,
              local_evento: document.data().cityInPerson,
              quantas_pessoas_participarao:
                document.data().quantityPersonsInPerson,
              considerar_coffee: document.data().coffeeIsPerson,
              teremos_almoco: document.data().lunchInPerson,
              // lunchPlaceInPerson: document.data().lunchPlaceInPerson,
              // lunchFabricInPerson: document.data().lunchFabricInPerson,
              // salad: document.data().salad,
              // mainCourse: document.data().mainCourse,
              // mainCoursePlus: document.data().mainCoursePlus,
              // followUp: document.data().followUp,
              // candy: document.data().candy,
              // fruit: document.data().fruit,
              // drink: document.data().drink,
              // restaurantInPerson: document.data().restaurantInPerson,
              ponto_encontro: document.data().meetingPointInPerson,
              ponto_encontro_outro: document.data().meetingPointOutherInPerson,
              observacao_sugestao_door_opener:
                document.data().doorOpenerSugestion,
              createdAt: document.data().createdAt,
              status: document.data().status,
            };

            arrEvents.push(newEvent);
            arrEventsAll.push(newEventAll);
          });

          setAllEventsInPerson(arrEvents);
          const newEvents = [...onlineEvents, ...arrEventsAll];
          setAllEvents(newEvents);
          setSuccessInPerson(true);
        });
    } catch (error) {
      console.log('ERROR ONLINE: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportEvent = async () => {
    if (allEventsOnline.length <= 0 && allEventsInPerson.length <= 0) return;

    const newArr = await [...allEventsOnline, ...allEventsInPerson];

    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(newArr);

    xlsx.utils.book_append_sheet(wb, ws, 'Todos_os_eventos');
    xlsx.writeFile(wb, 'Todos_os_eventos.xlsx');
  };

  return (
    <>
      <Header />
      <Container>
        {!isHiddenCards && (
          <div id="areaCards">
            <Card className="cardStyle">
              <Card.Body>
                <Card.Title>Eventos Online</Card.Title>
                <Card.Subtitle className="mt-2">
                  Gerencie todos os eventos online enviados
                </Card.Subtitle>
                <Button
                  className="mt-3"
                  onClick={() => {
                    if (cardSelected === 'online') {
                      setCardSelected('');
                      setIsHiddenCards(false);
                      return;
                    }

                    setCardSelected('online');
                    setIsHiddenCards(true);
                  }}
                >
                  {cardSelected === 'online'
                    ? 'Fechar card'
                    : 'Ver eventos online'}
                </Button>
              </Card.Body>
            </Card>

            <Card className="cardStyle">
              <Card.Body>
                <Card.Title>Eventos Presenciais</Card.Title>
                <Card.Subtitle className="mt-2">
                  Gerencie todos os eventos presenciais enviados
                </Card.Subtitle>
                <Button
                  className="mt-3"
                  onClick={() => {
                    if (cardSelected === 'presencial') {
                      setCardSelected('');
                      setIsHiddenCards(false);
                      return;
                    }

                    setCardSelected('presencial');
                    setIsHiddenCards(true);
                  }}
                >
                  {cardSelected === 'presencial'
                    ? 'Fechar card'
                    : 'Ver eventos presenciais'}
                </Button>
              </Card.Body>
            </Card>

            <Card className="cardStyle">
              <Card.Body>
                <Card.Title>Exportar eventos</Card.Title>
                <Card.Subtitle className="mt-2">
                  Exporte todos os eventos, presenciais e online
                </Card.Subtitle>
                <Button
                  className="mt-3 btn-success"
                  onClick={() => handleExportEvent()}
                >
                  Exportar
                </Button>
              </Card.Body>
            </Card>

            {successOnline && successInPerson && allEvents.length > 0 && (
              <Calendar
                data={allEvents}
                updatedEventsBase={() => getAllEventsOnline()}
              />
            )}
          </div>
        )}

        {cardSelected === 'online' && (
          <OnlineEvent
            handleClearSelected={() => {
              setCardSelected('');
              setIsHiddenCards(false);
            }}
          />
        )}

        {cardSelected === 'presencial' && (
          <PersonEvent
            handleClearSelected={() => {
              setCardSelected('');
              setIsHiddenCards(false);
            }}
          />
        )}
      </Container>
    </>
  );
}
