import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../contexts/auth';

import { Container } from './styles';
import React from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline';

export default function Admin() {
  const { signIn, loadingAuth, sendPassEmail, signUp } =
    useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const handleLogin = () => {
    if (!email || !pass) {
      toast.error('Informe suas credenciais de acesso para continuar!');
      return;
    }

    signIn(email, pass);
  };

  // const handleRegister = () => {
  //   if (!email) {
  //     toast.error('Informe suas credenciais de acesso para continuar!');
  //     return;
  //   }

  //   signUp(email);
  // };

  const handlePassReset = () => {
    if (!email) {
      toast.error('Informe o e-mail para continuar!');
      return;
    }

    sendPassEmail(email);
  };

  return (
    <Container>
      <div className="card">
        <h5 className="card-title mb-4">Dell Experience | Admin</h5>
        <FormGroup style={{ width: '100%' }}>
          <InputGroup>
            {/* <InputGroup.Text>
              <EnvelopeIcon />
            </InputGroup.Text> */}
            <Form.Control
              placeholder="E-mail de acesso"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {/*  */}
          <InputGroup className="mt-2">
            {/* <InputGroup.Text>
              <LockClosedIcon />
            </InputGroup.Text> */}
            <Form.Control
              placeholder="Senha de acesso"
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </InputGroup>
          {/*  */}
          <div className="btnArea">
            <Button
              className="btn btn-link"
              disabled={loadingAuth}
              onClick={() => handlePassReset()}
            >
              Esqueci minha senha
            </Button>
            <Button
              variant="primary"
              onClick={() => handleLogin()}
              // onClick={() => handleRegister()}
              disabled={loadingAuth}
            >
              {loadingAuth ? 'Aguarde...' : 'Acessar'}
            </Button>
          </div>
        </FormGroup>
      </div>
    </Container>
  );
}
