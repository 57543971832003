import React, { useState } from 'react';
import Lottie from 'lottie-react';

import {
  ArrowPathRoundedSquareIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  CakeIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  ComputerDesktopIcon,
  EnvelopeIcon,
  GiftIcon,
  HashtagIcon,
  IdentificationIcon,
  MapIcon,
  MapPinIcon,
  PhoneIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  TruckIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormLabel,
  FormText,
  InputGroup,
  NavLink,
  Row,
} from 'react-bootstrap';
import Calendar from 'react-calendar';
import { toast } from 'react-hot-toast';
import { Container } from './styles';
import firebase from '../../services/firebaseConnect';

type PersonArrData = {
  name: string;
  cpf: string;
  phone: string;
  email: string;
  type: string;
};

type LunchRestaurantOptionsProps = {
  restaurant: string;
  city: string;
};

//
export type OnlineEventData = {
  id?: string;
  accountId: string;
  outherDates: string;
  dellName: string;
  dellEmail: string;
  dellPhone: string;
  companyInvited: string;
  dateSelected: string;
  eventType: string;
  totalPersonsOnline: number;
  totalDellPersonsOnline: number;
  doorOpenerSugestion: string;
  createdAt: Date;
  status: 'Em Aberto' | 'Aceito' | 'Finalizado' | 'Arquivado';
  outhersInfos: '';
  // isFinished: boolean;
  // isArchivied: boolean;
};
//
//
export type PresencialEventData = {
  id?: string;
  accountId: string;
  outherDates: string;
  dellName: string;
  dellEmail: string;
  dellPhone: string;
  companyInvited: string;
  dateSelected: string;
  eventType: string;
  cityInPerson?: string;
  quantityPersonsInPerson?: number;
  coffeeIsPerson?: string;
  lunchInPerson?: string;
  lunchPlaceInPerson?: string;
  lunchFabricInPerson?: string;
  salad?: string[];
  mainCourse?: string;
  mainCoursePlus?: string;
  followUp?: string[];
  candy?: string;
  fruit?: string;
  drink?: string;
  restaurantInPerson?: string;
  meetingPointInPerson?: string;
  meetingPointOutherInPerson?: string;
  doorOpenerSugestion?: string;
  createdAt: Date;
  status: 'Em Aberto' | 'Aceito' | 'Finalizado' | 'Arquivado';
  outhersInfos: '';
  // isFinished: boolean;
  // isArchivied: boolean;
};
//

export const cityOptions = [
  'Fábrica - BRH',
  'Escritório São Paulo - CSC',
  'Coffee no cliente',
];

export const lunchRestaurantOptions: LunchRestaurantOptionsProps[] = [
  {
    restaurant:
      'Famiglia Gianni - Campinhas: Restaurante mais vezes escolhido. Não abre as segundas. Pedido de reserva deve ser feito com antecedência de no mínimo 3 horas.',
    city: 'Fábrica - BRH',
  },
  {
    restaurant:
      'Prime Italian - Campinas: Abre todos os dias. Pedido de reserva deve ser feito com antecedência mínima de no mínimo 3 horas.',
    city: 'Fábrica - BRH',
  },
  {
    restaurant:
      'Romeu - Hortolândia: Abre todos os dias. Pedido de reserva deve ser feito com antecedência de no mínimo 3 horas.',
    city: 'Escritório São Paulo - CSC',
  },
  {
    restaurant:
      'Pobre Juan - Campinas: Abre todos os dias. Pedido de reserva deve ser feito com antecedência de no mínimo 3 horas. Valor médio por pessoa: R$170,00 por pessoa.',
    city: 'Fábrica - BRH',
  },
];

export default function Home() {
  const [loadingEvent, setLoadingEvent] = useState(false);
  //
  const [dellName, setDellName] = useState('');
  const [dellEmail, setDellEmail] = useState('');
  const [dellPhone, setDellPhone] = useState('');
  const [companyInvited, setCompanyEnvited] = useState('');
  const [dateShow, setDateShow] = useState(false);
  const [dateString, setDateString] = useState('');
  const [dateSelected, setDateSelected] = useState('');
  // NOVO
  const [accountId, setAccountId] = useState('');
  const [outherDates, setOutherDates] = useState('');
  //
  const [eventType, setEventType] = useState('');
  const [eventExist] = useState('');
  //
  const [doorOpenerSugestion, setDoorOpenerSugestion] = useState('');

  // const [hostOnline, setHostOnline] = useState('');
  // ONLINE
  const [totalPersonsOnline, setTotalPersonsOnline] = useState(0);
  const [totalDellPersonsOnline, setTotalDellPersonsOnline] = useState(0);
  // const [cofirmOnlineQuantityPerson, setConfirmaOnlineQuantityPerson] = useState('');
  //
  const [infoPersonName, setInfoPersonName] = useState('');
  const [infoPersonCpf, setInfoPersonCpf] = useState('');
  const [infoPersonPhone, setInfoPersonPhone] = useState('');
  const [infoPersonEmail, setInfoPersonEmail] = useState('');
  const [infoPersonType, setInfoPersonType] = useState('');
  //
  const [infoPersonArr, setInfoPersonArr] = useState<PersonArrData[] | []>([]);
  const [infoDellPersonArr, setInfoDellPersonArr] = useState<
    PersonArrData[] | []
  >([]);
  //
  // const [giftOptionOnline, setGiftOptionOnline] = useState('');
  // const [giftOutherOptionOnline, setGiftOutherOptionOnline] = useState('');
  // const [giftDellOnline, setGiftDellOnline] = useState('');
  const [outhersInfos, setOuthersInfos] = useState('');

  // PRESENCIAL
  const [quantityPersonsInPerson, setQuantityPersonsInPerson] = useState(0);
  const [transportInPerson, setTransportInPerson] = useState('');
  //
  const [cityInPerson, setCityInPerson] = useState('');
  const [coffeeIsPerson, setCoffeeIsPerson] = useState('');
  const [lunchInPerson, setLunchInPerson] = useState('');
  const [lunchPlaceInPerson, setLunchPlaceInPerson] = useState('');
  const [lunchFabricInPerson, setLunchFabricInPerson] = useState('');
  const [restaurantInPerson, setRestaurantInPerson] = useState('');
  //
  const [salad, setSalad] = useState([]);
  const [mainCourse, setMainCourse] = useState('');
  const [mainCoursePlus, setMainCoursePlus] = useState('');
  const [followUp, setFollowUp] = useState([]);
  const [candy, setCandy] = useState('');
  const [fruit, setFruit] = useState('');
  const [drink, setDrink] = useState('');
  //
  const [meetingPointInPerson, setMeetingPointInPerson] = useState('');
  const [meetingPointOutherInPerson, setMeetingPointOutherInPerson] =
    useState('');
  // const [isTourInPerson, setIsTourInPerson] = useState('');
  // const [isTourHostInPerson, setTourHostInPerson] = useState('');
  //
  // const [giftOptionInPerson, setGiftOptionInPerson] = useState('');
  // const [giftOptionOutherInPerson, setGiftOptionOutherInPerson] = useState('');
  //
  const [sendedForm, setSendedForm] = useState(false);

  const onChangeDateSelected = (value: any) => {
    const date = new Date(value);
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

    const currentDate = `${day}/${month}/${date.getFullYear()}`;
    const parseDiff = `${date.getFullYear()}-${month}/${day}`;

    //
    const diffDays = getDiffDays(parseDiff);

    if (diffDays <= 5) {
      toast.error('O evento deve ter no mínimo 5 dias de antecedência');
      return;
    }

    // BLOCKED FIRST 3 WEEK MAIO-AGOSTO-NOVEMBRO
    //

    // SET INFORMATION
    setDateSelected(currentDate);
  };

  const getDiffDaysJS = (value: string) => {
    const currentDate = new Date();
    const providedDate = new Date(value);
    const differenceInMilliseconds =
      providedDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays < 5;
  };

  const getDiffDays = (value: any) => {
    const date = new Date();
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const todayDate = `${date.getFullYear()}-${month}/${day}`;

    console.log(new Date(todayDate));

    // @ts-ignore
    const diffInMs = new Date(todayDate) - new Date(value);

    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // @ts-ignore
    return Math.abs(parseInt(diffInDays));
  };

  const handleAddPersonOnlineArr = () => {
    if (
      !infoPersonName ||
      !infoPersonCpf ||
      !infoPersonPhone ||
      !infoPersonEmail ||
      !infoPersonType
    ) {
      toast.error(
        'Preencha os campos, nome, cpf, telefone, email e tipo para continuar!'
      );
      return;
    }

    if (infoPersonType === 'Cliente') {
      const data: PersonArrData = {
        name: infoPersonName,
        cpf: infoPersonCpf,
        phone: infoPersonPhone,
        email: infoPersonEmail,
        type: infoPersonType,
      };

      setInfoPersonArr((prev) => [...prev, data]);

      setInfoPersonName('');
      setInfoPersonCpf('');
      setInfoPersonEmail('');
      setInfoPersonPhone('');

      return;
    }

    if (infoPersonType === 'Dell') {
      const data: PersonArrData = {
        name: infoPersonName,
        cpf: infoPersonCpf,
        phone: infoPersonPhone,
        email: infoPersonEmail,
        type: infoPersonType,
      };

      setInfoDellPersonArr((prev) => [...prev, data]);

      setInfoPersonName('');
      setInfoPersonCpf('');
      setInfoPersonEmail('');
      setInfoPersonPhone('');

      return;
    }
  };

  const handleRemovePerson = async (person: PersonArrData) => {
    if (person.type === 'Cliente') {
      const filtered = await infoPersonArr.filter((item) => {
        if (
          item.name !== person.name &&
          item.cpf !== person.cpf &&
          item.phone !== person.phone &&
          item.email !== person.email &&
          item.type === person.type
        ) {
          return item;
        }
      });
      setInfoPersonArr(filtered);
      return;
    }

    if (person.type === 'Dell') {
      const filtered = await infoDellPersonArr.filter((item) => {
        if (
          item.name !== person.name &&
          item.cpf !== person.cpf &&
          item.phone !== person.phone &&
          item.email !== person.email &&
          item.type === person.type
        ) {
          return item;
        }
      });
      setInfoDellPersonArr(filtered);
      return;
    }
  };

  // const handleAddOnlineEvent = async () => {
  //   setLoadingEvent(true);
  //   setTimeout(() => {
  //     setLoadingEvent(false);
  //     setSendedForm(true);
  //     toast.success('Adicionado evento online...');
  //   }, 3000)
  // }

  // const handleAddInPersonEvent = async () => {
  //   setLoadingEvent(true);
  //   setTimeout(() => {
  //     setLoadingEvent(false);
  //     setSendedForm(true);
  //     toast.success('Adicionado evento presencial...');
  //   }, 3000)
  // }

  const handleSaladCheckboxChange = (event: any) => {
    const value = event.target.value;

    // @ts-ignore
    if (salad.includes(value)) {
      setSalad((prev) => prev.filter((item) => item !== value));
      return;
    }

    if (salad.length >= 2) {
      toast.error('Selecione no máximo 2 opções de salada!');
      return;
    }

    // @ts-ignore
    setSalad((prev) => [...prev, value]);
  };

  const handleFollowUpCheckboxChange = (event: any) => {
    const value = event.target.value;

    // @ts-ignore
    if (followUp.includes(value)) {
      setFollowUp((prev) => prev.filter((item) => item !== value));
      return;
    }

    if (followUp.length >= 2) {
      toast.error('Selecione no máximo 2 opções de acompanhamento!');
      return;
    }

    // @ts-ignore
    setFollowUp((prev) => [...prev, value]);
  };

  const handleAddOnlineEvent = () => {
    if (
      !accountId ||
      !dellName ||
      !dellEmail ||
      !dellPhone ||
      !companyInvited ||
      !dateString ||
      !eventType ||
      !totalPersonsOnline ||
      !totalDellPersonsOnline
    ) {
      toast.error('Preencha todos os campos para continuar!');
      return;
    }

    setLoadingEvent(true);
    try {
      const data = {
        accountId: accountId,
        outherDates: outherDates,
        dellName: dellName,
        dellEmail: dellEmail,
        dellPhone: dellPhone,
        companyInvited: companyInvited,
        dateSelected: dateString,
        eventType: eventType,
        totalPersonsOnline: totalPersonsOnline,
        totalDellPersonsOnline: totalDellPersonsOnline,
        doorOpenerSugestion: doorOpenerSugestion,
        outhersInfos: outhersInfos,
        createdAt: new Date(),
        status: 'Em Aberto',
      } as OnlineEventData;

      firebase
        .firestore()
        .collection('online_events')
        .add(data)
        .then(() => {
          toast.success('Seu evento foi enviado com sucesso!');
          setSendedForm(true);
        });
    } catch (error) {
      console.log('ERROR ONLINE EVENT: ', error);
    } finally {
      setLoadingEvent(false);
    }
  };

  const handleAddInPersonEvent = () => {
    if (
      !accountId ||
      !dellName ||
      !dellEmail ||
      !dellPhone ||
      !companyInvited ||
      !dateString ||
      !eventType ||
      !cityInPerson ||
      !quantityPersonsInPerson ||
      !coffeeIsPerson ||
      !lunchInPerson
    ) {
      toast.error('Preencha todos os campos do evento presencial!');
      return;
    }

    // if (lunchInPerson === 'sim' && !lunchPlaceInPerson) {
    //   toast.error('Informe se o almoço será na fábrica ou externo!');
    //   return;
    // }

    // if (lunchInPerson === 'Sim' && lunchPlaceInPerson === 'Fábrica' && !lunchFabricInPerson) {
    //   toast.error('Informe onde será o almoço!');
    //   return;
    // }

    // if (cityInPerson === 'Escritório São Paulo - CSC' && lunchFabricInPerson === 'Executivo' && (salad.length <= 0 || !mainCourse)) {
    //   toast.error('Informe a salada e prato principal!');
    //   return;
    // }

    // if (cityInPerson === 'Escritório São Paulo - CSC' && lunchFabricInPerson === 'Executivo' && mainCourse === 'Mignom' && !mainCoursePlus) {
    //   toast.error('Informe o Mignom!');
    //   return;
    // }

    // if (cityInPerson === 'Escritório São Paulo - CSC' && lunchFabricInPerson === 'Executivo' && followUp.length <= 0) {
    //   toast.error('Informe o acompanhamento!');
    //   return;
    // }

    // if (cityInPerson === 'Escritório São Paulo - CSC' && lunchFabricInPerson === 'Executivo' && (!candy || !fruit || !drink)) {
    //   toast.success('Escolha 1 doce, 1 fruta e uma bebida!');
    //   return;
    // }

    // EXTERNO

    // if (lunchInPerson === 'Sim' && lunchPlaceInPerson === 'Externo' && !restaurantInPerson) {
    //   toast.error('Selecione um restaurante!');
    //   return;
    // }

    // if (lunchInPerson === 'Sim' && lunchPlaceInPerson === 'Externo' && !meetingPointInPerson) {
    //   toast.error('Informe o ponto de encontro!');
    //   return;
    // }

    // if (lunchInPerson === 'Sim' && lunchPlaceInPerson === 'Externo' && meetingPointInPerson === 'Outro' && !meetingPointOutherInPerson) {
    //   toast.error('Informe um ponto de encontro!');
    //   return;
    // }

    //
    setLoadingEvent(true);
    try {
      const data = {
        accountId: accountId,
        outherDates: outherDates,
        dellName: dellName,
        dellEmail: dellEmail,
        dellPhone: dellPhone,
        companyInvited: companyInvited,
        dateSelected: dateString,
        eventType: eventType,
        cityInPerson: cityInPerson,
        quantityPersonsInPerson: quantityPersonsInPerson,
        coffeeIsPerson: coffeeIsPerson,
        lunchInPerson: lunchInPerson,
        // lunchPlaceInPerson: lunchPlaceInPerson,
        // lunchFabricInPerson: lunchFabricInPerson,
        // salad: salad,
        // mainCourse: mainCourse,
        // mainCoursePlus: mainCoursePlus,
        // followUp: followUp,
        // candy: candy,
        // fruit: fruit,
        // drink: drink,
        restaurantInPerson: restaurantInPerson,
        meetingPointInPerson: meetingPointInPerson,
        meetingPointOutherInPerson: meetingPointOutherInPerson,
        doorOpenerSugestion: doorOpenerSugestion,
        outhersInfos: outhersInfos,
        createdAt: new Date(),
        status: 'Em Aberto',
      } as PresencialEventData;

      firebase
        .firestore()
        .collection('person_events')
        .add(data)
        .then(() => {
          toast.success('Seu evento foi enviado com sucesso!');
          setSendedForm(true);
        });
    } catch (error) {
      console.log('ERROR PERSON EVENT: ', error);
    } finally {
      setLoadingEvent(false);
    }
  };

  if (loadingEvent) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Lottie
          autoPlay
          loop
          animationData={require('../../assets/loading.json')}
          style={{ width: 150, height: 150 }}
        />
        <p>Aguarde...</p>
      </div>
    );
  }

  if (sendedForm) {
    return (
      <div id="fullArea">
        <div className="sectionHome">
          <div className="sendedForm">
            <div
              style={{
                background: '#dc3545',
                padding: 10,
                borderRadius: 5,
                marginBottom: 45,
                width: '100%',
                color: 'white',
              }}
            >
              <p style={{ margin: 0, fontSize: 16 }}>
                *ATENÇÃO: A inscrição não garante que o evento esteja
                automaticamente aprovado.
                <br />
                Aguarde o contato para confirmação da aprovação do evento.
              </p>
            </div>

            <h1>
              Agradecemos pela reserva, os próximos passos que acontecerão a
              partir de agora:
            </h1>
            <ul>
              <li>
                Vamos analisar o escopo do evento, disponibilidade de salas e
                retornar o seu contato em breve
              </li>
              <li>
                Na sequência, se aprovada a reserva enviaremos o e-mail convite
                para você disparar aos convidados do evento.
              </li>
              <li>
                No e-mail convite, há um botão de confirmação de presença que
                tanto os clientes quanto os participantes da Dell precisam
                confirmar.
              </li>
            </ul>
            <p>
              POR QUE EU PRECISO FAZER ISSO? Porque precisamos liberar o acesso
              na fábrica e emitir seguro transporte caso haja transporte
              incluído na visita.
            </p>
          </div>
        </div>
        <div className="sectionPersons"></div>
      </div>
    );
  }

  return (
    <div id="fullArea">
      <section className="sectionHome">
        <p style={{ maxWidth: 600, width: '100%', fontSize: '0.9rem' }}>
          <strong>
            Seja bem-vindo(a) ao formulário de pré-reserva para eventos de
            <br />
            Dell Experience CSG.
          </strong>
          <br />
          Essa inscrição é uma solicitação que será encaminhada para aprovação
          do time de marketing e não garante a execução do evento.
        </p>
        <p
          style={{
            maxWidth: 600,
            width: '100%',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        >
          Por favor, não comunique para os clientes até que sua solicitação seja
          aprovada.
        </p>
        {/*  */}
        <Card>
          <Card.Body>
            <FormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <UserIcon />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nome completo (Solicitante Dell)"
                  value={dellName}
                  onChange={(e) => setDellName(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>
                  <HashtagIcon />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Account ID (Solicitante Dell)"
                  value={accountId}
                  onChange={(e) => setAccountId(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>
                  <EnvelopeIcon />
                </InputGroup.Text>
                <Form.Control
                  placeholder="E-mail (Solicitante Dell)"
                  value={dellEmail}
                  onChange={(e) => setDellEmail(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>
                  <PhoneIcon />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Celular (Solicitante Dell)"
                  value={dellPhone}
                  onChange={(e) => setDellPhone(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <BuildingOfficeIcon />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Nome da empresa convidada"
                  value={companyInvited}
                  onChange={(e) => setCompanyEnvited(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <div className="divisor-line"></div>
            <FormGroup>
              <InputGroup>
                <InputGroup.Text>
                  <CalendarDaysIcon />
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  placeholder="Selecione uma data"
                  onChange={(e) => {
                    if (getDiffDaysJS(e.target.value)) {
                      toast.error(
                        'O evento deve ter no mínimo 5 dias de antecedência'
                      );
                      return;
                    }
                    const numDate = e.target.value.split('-');
                    const converted = `${numDate[2]}/${numDate[1]}/${numDate[0]}`;
                    setDateString(converted);
                  }}
                />
                {/* <Form.Control
                  contentEditable={false}
                  value={dateShow ? `Ocultar calendário: ${!dateSelected ? '00/00/0000' : dateSelected}` : `Mostrar calendário: ${!dateSelected ? '00/00/0000' : dateSelected}`}
                  style={{ cursor: 'pointer', outline: 'none', boxShadow: 'none' }}
                  onClick={() => setDateShow(!dateShow)}
                /> */}
              </InputGroup>
              <p
                style={{
                  width: '100%',
                  padding: 5,
                  borderRadius: 3,
                  background: '#009bbb',
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                Data selecionada: {dateString}
              </p>
            </FormGroup>
            {/* REALIZAR VALIDAÇÃO SE A DATA SELECIONADA É AO MENOS 5 DIAS ANTES DO EVENTO */}
            {/* {
              dateShow &&
              <Calendar
                locale='pt-BR'
                onChange={value => onChangeDateSelected(value)}
                value={dateSelected}
              />
            } */}
            <div className="divisor-line"></div>
            <FormGroup>
              <Form.Control
                as="textarea"
                rows={3}
                style={{ resize: 'none' }}
                placeholder="Além da data selecionada, por favor indicar mais 2 datas possíveis para o evento, assim caso a primeira data escolhida não esteja disponível poderemos considerar essas outras 2 datas."
                value={outherDates}
                onChange={(e) => setOutherDates(e.target.value)}
              />
            </FormGroup>
            <div className="divisor-line"></div>

            {dateString && (
              <>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      Evento{' '}
                      {!eventType ? (
                        <QuestionMarkCircleIcon style={{ marginLeft: 3 }} />
                      ) : eventType === 'Online' ? (
                        <ComputerDesktopIcon style={{ marginLeft: 3 }} />
                      ) : (
                        <TruckIcon style={{ marginLeft: 3 }} />
                      )}
                    </InputGroup.Text>
                    {/* REALIZAR VALIDAÇÃO SE EXISTE UM EVENTO DO MESMO TIPO NO MESMO DIA */}
                    {['Online', 'Presencial'].map((item) => (
                      <Form.Check
                        type="radio"
                        name="typeEvent"
                        label={item}
                        id={item}
                        key={item}
                        onChange={() => setEventType(item)}
                        disabled={item === eventExist ? true : false}
                        style={{ marginTop: 5, marginLeft: 10 }}
                      />
                    ))}
                  </InputGroup>
                </FormGroup>
                <div className="divisor-line"></div>
              </>
            )}

            {eventType !== '' && (
              <>
                <Card.Subtitle>Evento {eventType}</Card.Subtitle>

                {/* <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <UserIcon />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Nome completo do anfitrião Dell (acompanhante da visita)"
                      value={hostOnline}
                      onChange={e => setHostOnline(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup> */}
                {eventType === 'Online' && (
                  <>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <UserGroupIcon />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Quantidade pessoas Dell"
                          value={
                            totalPersonsOnline <= 0 ? '' : totalPersonsOnline
                          }
                          onChange={(e) =>
                            setTotalPersonsOnline(parseInt(e.target.value))
                          }
                          type="number"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <UserGroupIcon />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Quantidade de convidados"
                          value={
                            totalDellPersonsOnline <= 0
                              ? ''
                              : totalDellPersonsOnline
                          }
                          onChange={(e) =>
                            setTotalDellPersonsOnline(parseInt(e.target.value))
                          }
                          type="number"
                        />
                      </InputGroup>
                    </FormGroup>
                    {/* {
                      (totalPersonsOnline && totalDellPersonsOnline) &&
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Text>
                            <UserGroupIcon />
                          </InputGroup.Text>
                          <Form.Select
                            value={cofirmOnlineQuantityPerson}
                            onChange={e => setConfirmaOnlineQuantityPerson(e.target.value)}
                          >
                            <option>Está certo(a) de que será essa a quantidade de pessoas que teremos que considerar para o evento?</option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                          </Form.Select>
                        </InputGroup>
                      </FormGroup>
                    } */}
                  </>
                )}
                {eventType === 'Presencial' && (
                  <>
                    {/* VERIFICAR CIDADES ARRAY COM CIDADE, SALA, QUANTIDADE PESSOAS : ADD ID */}
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <MapPinIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={cityInPerson}
                          onChange={(e) => setCityInPerson(e.target.value)}
                        >
                          <option>Selecione o local do evento</option>
                          {cityOptions.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <UserGroupIcon />
                        </InputGroup.Text>
                        {/* <Form.Select
                          value={quantityPersonsInPerson}
                          onChange={(e) =>
                            setQuantityPersonsInPerson(e.target.value)
                          }
                        >
                          <option>
                            Quantas pessoas no total participarão do evento?
                          </option>
                          <option value="5">Até 5</option>
                          <option value="13">Até 13</option>
                          <option value="20">Até 20</option>
                          <option value="24">Até 24</option>
                        </Form.Select> */}
                        <Form.Control
                          placeholder="Quantas pessoas no total participarão do evento?"
                          value={
                            quantityPersonsInPerson <= 0
                              ? ''
                              : quantityPersonsInPerson
                          }
                          onChange={(e) =>
                            setQuantityPersonsInPerson(parseInt(e.target.value))
                          }
                          type="number"
                          min={0}
                          max={13}
                        />
                      </InputGroup>
                    </FormGroup>
                    {quantityPersonsInPerson > 0 &&
                      cityInPerson === 'Fábrica - BRH' && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            background: '#f4f4f4',
                            paddingLeft: 10,
                            borderRadius: 5,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              background: '#f4f4f4',
                              paddingLeft: 5,
                              borderRadius: 5,
                              paddingTop: 10,
                            }}
                          >
                            {/* <TruckIcon style={{ width: 24, height: 24 }} />
                            <p style={{ paddingTop: 20, marginLeft: 5 }}>
                              Meio de transporte: &nbsp;
                              {quantityPersonsInPerson <= 13
                                ? 'Van'
                                : 'Micro-ônibus'}
                            </p> */}
                          </div>
                          <p
                            style={{
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              color: '#999',
                            }}
                          >
                            *Solicitações que demandem mais de 13 pessoas, por
                            favor inserir a quantidade necessária no campo{' '}
                            <b>outras definições</b> abaixo e entrar em contato
                            com seu <b>FMM</b> para solicitar autorização.
                          </p>
                        </div>
                      )}
                  </>
                )}
                <div className="divisor-line"></div>

                {/* <h4 className="infoInput">
                  *Informe os dados dos participantes do evento.
                </h4>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <UserIcon />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Nome"
                      value={infoPersonName}
                      onChange={e => setInfoPersonName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup style={{ marginRight: 5 }}>
                  <InputGroup>
                    <InputGroup.Text>
                      <IdentificationIcon />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="CPF"
                      value={infoPersonCpf}
                      onChange={e => setInfoPersonCpf(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <PhoneIcon />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Telefone"
                      value={infoPersonPhone}
                      onChange={e => setInfoPersonPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <EnvelopeIcon />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="E-mail"
                      value={infoPersonEmail}
                      onChange={e => setInfoPersonEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <BriefcaseIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={infoPersonType}
                      onChange={e => setInfoPersonType(e.target.value)}
                    >
                      <option>Selecione o tipo de pessoa</option>
                      <option value="Cliente">Cliente</option>
                      <option value="Dell">Dell</option>
                    </Form.Select>
                    <Button variant="outline-primary" id="button-addon2" onClick={() => handleAddPersonOnlineArr()}>
                      <PlusCircleIcon />
                    </Button>
                  </InputGroup>
                </FormGroup>

                <div className="divisor-line"></div> */}

                {/* {
                  eventType === 'Online' &&
                  <>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <GiftIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={giftOptionOnline}
                          onChange={e => setGiftOptionOnline(e.target.value)}
                        >
                          <option>Selecione o brinde (entrega)</option>
                          <option value="Cesta">Cesta de café da manhã</option>
                          <option value="Outra">Outra opção</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                    {
                      giftOptionOnline === 'Cesta' && <img src={require('../../assets/cesta_cafe.png')} alt='gift' style={{ maxWidth: 600, width: '100%' }} />
                    }
                    {
                      giftOptionOnline === 'Outra' &&
                      <>
                        <img src={require('../../assets/outra_opcao.png')} alt='gift' style={{ maxWidth: 600, width: '100%', marginBottom: 10 }} />
                        <FormGroup>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            style={{ resize: 'none' }}
                            placeholder="Descreva aqui uma outra opção..."
                            value={giftOutherOptionOnline}
                            // onChange={e => setGiftOutherOptionOnline(e.target.value)}
                          />
                        </FormGroup>
                        <h4 className="infoInput" style={{ marginTop: 5 }}>
                          *A opção solicitada pode ou não ser atendida mediante prazo do evento. Qualquer novo pedido fora do padrão pré-estabelecido deve ser solicitado com um prazo de 30 dias de antecedência do evento.
                        </h4>
                      </>
                    } */}
                {/* <div className="divisor-line"></div>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <GiftIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={giftDellOnline}
                          onChange={e => setGiftDellOnline(e.target.value)}
                        >
                          <option>Os participantes Dell vão receber cestas?</option>
                          <option value="Sim">Sim</option>
                          <option value="Não">Não</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup> */}
              </>
            )}

            {eventType === 'Presencial' && (
              <>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <CakeIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={coffeeIsPerson}
                      onChange={(e) => setCoffeeIsPerson(e.target.value)}
                    >
                      <option>
                        Devemos considerar coffee para esse evento?
                      </option>
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Form.Select>
                  </InputGroup>
                </FormGroup>
                {/* {
                  coffeeIsPerson === 'Simples' && <img src={require('../../assets/cafe_simples.png')} alt='coffee' style={{ maxWidth: 600, width: '100%' }} />
                }
                {
                  coffeeIsPerson === 'Intermediario' && <img src={require('../../assets/cafe_intermediario.png')} alt='coffee' style={{ maxWidth: 600, width: '100%' }} />
                } */}
                <div className="divisor-line"></div>
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <CakeIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={lunchInPerson}
                      onChange={(e) => setLunchInPerson(e.target.value)}
                    >
                      <option>Teremos almoço no evento?</option>
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Form.Select>
                  </InputGroup>
                </FormGroup>
                {lunchInPerson === 'Sim' && (
                  <p className="pMuted">
                    *Almoço na fábrica - fornecido pela equipe GRSA.
                  </p>
                )}
                {/* {
                  (lunchInPerson === 'Sim') &&
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <MapIcon />
                      </InputGroup.Text>
                      <Form.Select
                        value={lunchPlaceInPerson}
                        onChange={e => setLunchPlaceInPerson(e.target.value)}
                      >
                        <option>O almoço será na fábrica ou externo?</option>
                        <option value="Fábrica">Almoço na fábrica</option>
                        <option value="Externo">Almoço externo</option>
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                } */}
                {/* {
                  lunchPlaceInPerson === 'Fábrica' &&
                  <p className="pMuted">*Sujeito a cardápio fornecido no dia pela fornecedora GRSA.</p>
                } */}
                {/* {
                  (lunchInPerson === 'Sim' && lunchPlaceInPerson === 'Fábrica') &&
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <MapPinIcon />
                      </InputGroup.Text>
                      <Form.Select
                        value={lunchFabricInPerson}
                        onChange={e => setLunchFabricInPerson(e.target.value)}
                      >
                        <option>Onde será o almoço?</option>
                        <option value="Refeitório">Almoço refeitório</option>
                        {
                          parseInt(quantityPersonsInPerson) <= 20 &&
                          <option value="Executivo">Almoço executivo</option>
                        }
                      </Form.Select>
                    </InputGroup>
                  </FormGroup>
                } */}
                {/*  */}
                {/* {
                  (cityInPerson === 'Escritório São Paulo - CSC' && lunchFabricInPerson === 'Executivo') &&
                  <>
                    <FormGroup style={{ marginTop: 20, marginBottom: 20 }}>
                      <FormLabel>Saladas (escolha duas):</FormLabel>

                      <InputGroup className="checkOptionsArea">

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Mix de folhas verdes'
                            // @ts-ignore
                            checked={salad.includes('Mix de folhas verdes')}
                            onChange={handleSaladCheckboxChange}
                          />
                          Mix de folhas verdes
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Salada caprese'
                            // @ts-ignore
                            checked={salad.includes('Salada caprese')}
                            onChange={handleSaladCheckboxChange}
                          />
                          Salada caprese
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Salada Ceasar'
                            // @ts-ignore
                            checked={salad.includes('Salada Ceasar')}
                            onChange={handleSaladCheckboxChange}
                          />
                          Salada Ceasar
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Salada de batata com maionese de limão'
                            // @ts-ignore
                            checked={salad.includes('Salada de batata com maionese de limão')}
                            onChange={handleSaladCheckboxChange}
                          />
                          Salada de batata com maionese de limão
                        </FormLabel>

                      </InputGroup>
                    </FormGroup>
                    <div className="divisor-line"></div>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <CakeIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={mainCourse}
                          onChange={e => setMainCourse(e.target.value)}
                        >
                          <option>Prato Principal</option>
                          <option value="Mignom">Mignom</option>
                          <option value="Salmão">Salmão</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                    {
                      mainCourse === 'Mignom' &&
                      <FormGroup>
                        <InputGroup>
                          <InputGroup.Text>
                            <CakeIcon />
                          </InputGroup.Text>
                          <Form.Select
                            value={mainCoursePlus}
                            onChange={e => setMainCoursePlus(e.target.value)}
                          >
                            <option>Mignom pode ser:</option>
                            <option value="Strogonoff">Strogonoff</option>
                            <option value="Filé ao molho de champignon">Filé ao molho de champignon</option>
                            <option value="Parmegiana">Parmegiana</option>
                          </Form.Select>
                        </InputGroup>
                      </FormGroup>
                    }
                    <div className="divisor-line"></div>
                    <FormGroup style={{ marginTop: 20, marginBottom: 20 }}>
                      <FormLabel>Acompanhamentos (escolher 2):</FormLabel>

                      <InputGroup className="checkOptionsArea">

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Arroz branco'
                            // @ts-ignore
                            checked={followUp.includes('Arroz branco')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Arroz branco
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Massa simples ao molho pesto ou sugo'
                            // @ts-ignore
                            checked={followUp.includes('Massa simples ao molho pesto ou sugo')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Massa simples ao molho pesto ou sugo
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Batata corada'
                            // @ts-ignore
                            checked={followUp.includes('Batata corada')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Batata corada
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Batata gratinada'
                            // @ts-ignore
                            checked={followUp.includes('Batata gratinada')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Batata gratinada
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Legumes sauté'
                            // @ts-ignore
                            checked={followUp.includes('Legumes sauté')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Legumes sauté
                        </FormLabel>

                        <FormLabel className="checkArea">
                          <Form.Check
                            value='Brócoles com couve-flor sauté'
                            // @ts-ignore
                            checked={followUp.includes('Brócoles com couve-flor sauté')}
                            onChange={handleFollowUpCheckboxChange}
                          />
                          Brócoles com couve-flor sauté
                        </FormLabel>

                      </InputGroup>
                    </FormGroup>
                    <div className="divisor-line"></div>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <CakeIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={candy}
                          onChange={e => setCandy(e.target.value)}
                        >
                          <option>Doces: escolha 1 tipo</option>
                          <option value="Pudim de leite">Pudim de leite</option>
                          <option value="Brigadeirão">Brigadeirão</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                    <div className="divisor-line"></div>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <CakeIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={fruit}
                          onChange={e => setFruit(e.target.value)}
                        >
                          <option>Frutas: escolha 1 tipo</option>
                          <option value="Frutas laminadas">Frutas laminadas</option>
                          <option value="Salada de frutas">Salada de frutas</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                    <div className="divisor-line"></div>
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <CakeIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={drink}
                          onChange={e => setDrink(e.target.value)}
                        >
                          <option>Selecione uma bebida</option>
                          <option value="Água mineral com e sem gás">Água mineral com e sem gás</option>
                          <option value="Suco de laranja">Suco de laranja</option>
                          <option value="Refrigerante">Refrigerante</option>
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                  </>
                } */}
                {/* RESTAURANTE DE ACORDO COM A CIDADE */}
                {lunchInPerson === 'Sim' &&
                  lunchPlaceInPerson === 'Externo' && (
                    <FormGroup>
                      <InputGroup>
                        <InputGroup.Text>
                          <MapIcon />
                        </InputGroup.Text>
                        <Form.Select
                          value={restaurantInPerson}
                          onChange={(e) =>
                            setRestaurantInPerson(e.target.value)
                          }
                        >
                          <option>Escolha um restaurante</option>
                          {lunchRestaurantOptions.map((item) => {
                            if (cityInPerson === item.city) {
                              return (
                                <option
                                  key={item.restaurant}
                                  value={item.restaurant}
                                >
                                  {item.restaurant}
                                </option>
                              );
                            }
                          })}
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                  )}
                <div className="divisor-line"></div>
                {/* LOCAIS DE ACORDO COM A CIDADE */}
                <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <MapPinIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={meetingPointInPerson}
                      onChange={(e) => setMeetingPointInPerson(e.target.value)}
                    >
                      <option>Escolha um ponto de encontro</option>
                      <option value="Sede Dell - R. Verbo Divino, 1488 - Granja Julieta, São Paulo - SP, 04719-002, Brasil">
                        Sede Dell - R. Verbo Divino, 1488 - Granja Julieta, São
                        Paulo - SP, 04719-002, Brasil
                      </option>
                      <option value="Outro">
                        Outro ponto de encontro? Preencher endereço completo com
                        CEP
                      </option>
                    </Form.Select>
                  </InputGroup>
                </FormGroup>
                {meetingPointInPerson === 'Outro' && (
                  <FormGroup>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: 'none' }}
                      placeholder="Informe o endereço completo do ponto de encontro"
                      value={meetingPointOutherInPerson}
                      onChange={(e) =>
                        setMeetingPointOutherInPerson(e.target.value)
                      }
                    />
                  </FormGroup>
                )}
                {/* <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <ArrowPathRoundedSquareIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={isTourInPerson}
                      onChange={e => setIsTourInPerson(e.target.value)}
                    >
                      <option>Teremos Tour na fábrica nesse dia?</option>
                      <option value="Sim">Sim</option>
                      <option value="Não">Não</option>
                    </Form.Select>
                  </InputGroup>
                </FormGroup>
                {
                  isTourInPerson === 'Sim' &&
                  <FormGroup>
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Nome do anfitrião da fábrica (lider visita)"
                        value={isTourHostInPerson}
                        onChange={e => setTourHostInPerson(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                } */}
                <div className="divisor-line"></div>
                {/* <FormGroup>
                  <InputGroup>
                    <InputGroup.Text>
                      <GiftIcon />
                    </InputGroup.Text>
                    <Form.Select
                      value={giftOptionInPerson}
                      onChange={e => setGiftOptionInPerson(e.target.value)}
                    >
                      <option>Escolha uma opção de brinde</option>
                      <option value="Kit">Kit (sacochila, moleskine e caneta)</option>
                      <option value="Outra">Outra opção</option>
                    </Form.Select>
                  </InputGroup>
                </FormGroup>
                {
                  giftOptionInPerson === 'Kit' && <img src={require('../../assets/kit.png')} alt='gift' style={{ maxWidth: 600, width: '100%' }} />
                }
                {
                  giftOptionInPerson === 'Outra' &&
                  <>
                    <img src={require('../../assets/outro.png')} alt='gift' style={{ maxWidth: 600, width: '100%', marginBottom: 10 }} />
                    <FormGroup>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder="Descreva aqui uma outra opção..."
                        value={giftOptionOutherInPerson}
                        onChange={e => setGiftOptionOutherInPerson(e.target.value)}
                      />
                    </FormGroup>
                    <h4 className="infoInput" style={{ marginTop: 5 }}>
                      *A opção solicitada pode ou não ser atendida mediante prazo do evento. Qualquer novo pedido fora do padrão pré-estabelecido deve ser solicitado com um prazo de 30 dias de antecedência do evento.
                    </h4>
                  </>
                } */}
              </>
            )}

            <div className="divisor-line"></div>

            <FormGroup>
              <Form.Label>Outras definições:</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Outras definições..."
                value={outhersInfos}
                onChange={(e) => setOuthersInfos(e.target.value)}
              />
            </FormGroup>

            <div className="divisor-line"></div>

            <FormGroup>
              <Form.Label>
                Alguma outra observação ou sugestão a ser considerada para este
                Dell Experience?
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                style={{ resize: 'none' }}
                placeholder="Informe aqui..."
                value={doorOpenerSugestion}
                onChange={(e) => setDoorOpenerSugestion(e.target.value)}
              />
            </FormGroup>
          </Card.Body>

          <Card.Footer>
            <Button
              variant="primary"
              disabled={loadingEvent}
              onClick={() =>
                eventType === 'Online'
                  ? handleAddOnlineEvent()
                  : handleAddInPersonEvent()
              }
            >
              {loadingEvent ? 'Aguarde...' : 'Enviar Formulário'}
            </Button>
            {/* <br />
            <p>
              Deseja enviar o convite para os clientes? &nbsp;
              <a download href="./assets/j4129_-_emkt_door_opener_dell_fy24q1.oft">
                Clique aqui para baixar o Emkt editável.
              </a>
            </p> */}
          </Card.Footer>
        </Card>
      </section>
      {/*  */}
      <section className="sectionPersons">
        {/* PESSOAS DELL */}
        {infoDellPersonArr.length > 0 && (
          <>
            <div className="divisor-line"></div>
            <h3>Participantes Dell</h3>
            <div className="personCardArea">
              {infoDellPersonArr.map((person) => (
                <div key={person.cpf} className="personCard">
                  <div className="headerPersonCard">
                    <h5>
                      {person.name.length > 24
                        ? `${person.name.slice(0, 24)}...`
                        : person.name}
                    </h5>
                    <button onClick={() => handleRemovePerson(person)}>
                      <TrashIcon width={18} height={18} />
                    </button>
                  </div>
                  <div className="bodyPersonCard">
                    <p>
                      <IdentificationIcon width={18} height={18} /> &nbsp;{' '}
                      {person.cpf}
                    </p>
                    <p>
                      <PhoneIcon width={18} height={18} /> &nbsp; {person.phone}
                    </p>
                    <p>
                      <EnvelopeIcon width={18} height={18} /> &nbsp;{' '}
                      {person.email.length > 24
                        ? `${person.email.slice(0, 24)}...`
                        : person.email}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {/* PESSOAS CLIENTES */}
        {infoPersonArr.length > 0 && (
          <>
            <br />
            <div className="divisor-line"></div>
            <h3>Convidados (Clientes)</h3>
            <div className="personCardArea">
              {infoPersonArr.map((person) => (
                <div key={person.cpf} className="personCard">
                  <div className="headerPersonCard">
                    <h5>
                      {person.name.length > 24
                        ? `${person.name.slice(0, 24)}...`
                        : person.name}
                    </h5>
                    <button onClick={() => handleRemovePerson(person)}>
                      <TrashIcon width={18} height={18} />
                    </button>
                  </div>
                  <div className="bodyPersonCard">
                    <p>
                      <IdentificationIcon width={18} height={18} /> &nbsp;{' '}
                      {person.cpf}
                    </p>
                    <p>
                      <PhoneIcon width={18} height={18} /> &nbsp; {person.phone}
                    </p>
                    <p>
                      <EnvelopeIcon width={18} height={18} /> &nbsp;{' '}
                      {person.email.length > 24
                        ? `${person.email.slice(0, 24)}...`
                        : person.email}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>
    </div>
  );
}
