import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  padding: 0.7% 4%;
  background: #eee;
  margin-bottom: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  h1 {
    font-size: 1.5rem;
  }

  #areaInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    gap: 10px;

    p {
      display: flex;
      flex-direction: column;
      line-height: 17px;
      margin-top: 7%;
    }
  }
`;
