import { useContext } from 'react';
import { Container } from './styles';
import { AuthContext } from '../../contexts/auth';
import { Button } from 'react-bootstrap';

export default function Header() {
  const { user, signOut } = useContext(AuthContext);

  return (
    <Container>
      <h1>Dell Experience | Admin</h1>
      <div id="areaInfo">
        <p>
          <b>Bem-vindo de volta,</b>
          <span className="text-muted">{user?.email}</span>
        </p>
        <Button variant="danger" className="btn btn-outline" onClick={signOut}>
          Sair
        </Button>
      </div>
    </Container>
  );
}
