import React, { useEffect, useState } from "react";
import { format, isSameDay, addMonths, subMonths, parse } from 'date-fns';
import { Container, Modal } from "./styles";
import { ptBR } from "date-fns/locale";
import { OnlineEventData, PresencialEventData } from "../../screens/Home";
import firebase from '../../services/firebaseConnect';

import {
  HeaderEvent,
  EventsArea,
  CardEvent,
  GraphicArea,
  AllColumns
} from '../../screens/Dashboard/OnlineEventStyles';
import { Button, Form, FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import toast from "react-hot-toast";

type CalendarProps = {
  data: any[],
  updatedEventsBase: () => void;
}

export function Calendar({ data, updatedEventsBase }: CalendarProps) {

  const [eventsArr, setEventsArr] = useState<any[] | []>([])
  const [eventSelected, setEventSelected] = useState<any[] | []>([]);
  const [currentEvent, setCurrentEvent] = useState<any | null>(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEventsArr(data);
  }, [data])

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleUpdateEventOnlineStatus = (event: any) => {
    if (!currentStatus) return;

    if (currentStatus === event.status) {
      toast.error('Este evento já está com esse status definido!');
      return;
    }

    setLoading(true);
    try {

      firebase.firestore().collection('online_events').doc(event.id).update({
        status: currentStatus
      })
        .then(async () => {
          const filtered = await eventsArr.filter(item => item.id !== event.id);
          //@ts-ignore
          event.status = currentStatus;
          setEventsArr([event, ...filtered]);
          toast.success('Status atualizado com sucesso!');
          setCurrentStatus('');
        })

    }
    catch (error) {
      console.log('ERRO STATUS: ', error)
    }
    finally {
      setLoading(false);
    }
  }

  const handleUpdateEventInPersonStatus = (event: any) => {
    if (!currentStatus) return;

    if (currentStatus === event.status) {
      toast.error('Este evento já está com esse status definido!');
      return;
    }

    setLoading(true);
    try {

      firebase.firestore().collection('person_events').doc(event.id).update({
        status: currentStatus
      })
        .then(async () => {
          const filtered = await eventsArr.filter(item => item.id !== event.id);
          //@ts-ignore
          event.status = currentStatus;
          setEventsArr([event, ...filtered]);
          toast.success('Status atualizado com sucesso!');
          setCurrentStatus('');
        })

    }
    catch (error) {
      console.log('ERRO STATUS: ', error)
    }
    finally {
      setLoading(false);
    }
  }

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  };

  const today = new Date();
  const monthStart = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const monthEnd = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  const startDate = new Date(monthStart);
  startDate.setDate(startDate.getDate() - startDate.getDay());
  const endDate = new Date(monthEnd);
  endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));

  const calendar = [];
  let currentRow = [];

  for (let i = 0; i < 42; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + i);
    currentRow.push(currentDate);

    if (currentRow.length === 7) {
      calendar.push(currentRow);
      currentRow = [];
    }
  }

  const isDatePresentInDatabase = (date: any) => {
    const formattedDate = format(date, 'dd/MM/yyyy');
    return eventsArr.some((event) => {
      if (!event.dateSelected) return false; // Ignora objetos sem data_selecionada
      const eventDate = parse(event.dateSelected, 'dd/MM/yyyy', new Date());
      return isSameDay(date, eventDate);
    });
  };

  const handleDateClick = (date: any) => {
    const formattedDate = format(date, 'dd/MM/yyyy');
    //@ts-ignore
    const filtered = eventsArr.filter(event => event.dateSelected === formattedDate);
    setEventSelected(filtered);
  };

  return (
    <>
      {
        eventSelected.length > 0 &&
        <Modal>
          <button className="btnClose" onClick={() => {
            updatedEventsBase()
            setEventSelected([])
          }}>X</button>
          <div className="modalContent">

            {
              eventSelected.map(event => {

                if (event.eventType === 'Online') {
                  return (
                    <CardEvent key={event.id}>
                      <div className="cardHeader">
                        <div className="headerInfos">
                          <p>{event.dateSelected}</p>
                          <h3>{event.dellName}</h3>
                        </div>
                        <div className="headerBtns">
                          <Button className='btn btn-secondary' onClick={() => currentEvent?.id === event.id ? setCurrentEvent(null) : setCurrentEvent(event)}>
                            {
                              currentEvent?.id === event.id ? 'Mostrar menos' : 'Mostrar mais'
                            }
                          </Button>
                        </div>
                      </div>
                      {
                        currentEvent?.id === event.id &&
                        <div className="cardContent">
                          <h5>Informações Dell:</h5>
                          <p><b>Nome representante:</b> {event.dellName}</p>
                          <p><b>E-mail representante:</b> {event.dellEmail}</p>
                          <p><b>Telefone representante:</b> {event.dellPhone}</p>
                          <p><b>Empresa convidada:</b> {event.companyInvited}</p>
                          <p><b>Data evento:</b> {event.dateSelected}</p>
                          <p><b>Tipo de evento:</b> {event.eventType}</p>
                          {/*  */}
                          <h5>Informações do Evento:</h5>
                          <p><b>Total de participantes: </b> {event.totalPersonsOnline}</p>
                          <p><b>Total de convidados Dell: </b> {event.totalDellPersonsOnline}</p>
                          <hr />
                          <p><b>Sugestões para Door Opener: </b> {event.doorOpenerSugestion || 'Sem sugestões'}</p>
                          <div className="cardFooter">
                            <FormGroup>
                              <FormLabel><b>Status do evento:</b> ({event.status})</FormLabel>
                              <InputGroup>
                                <Form.Select
                                  value={currentStatus}
                                  onChange={e => setCurrentStatus(e.target.value)}
                                >
                                  <option>Escolha um status</option>
                                  {
                                    ['Em Aberto', 'Aceito', 'Finalizado', 'Arquivado'].map(item => (
                                      <option key={item} value={item}>{item}</option>
                                    ))
                                  }
                                </Form.Select>
                                <Button
                                  disabled={loading}
                                  onClick={() => handleUpdateEventOnlineStatus(event)}
                                >
                                  {loading ? 'Aguarde...' : 'Atualizar Status'}
                                </Button>
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </div>
                      }
                    </CardEvent>
                  )
                }

                return (
                  <CardEvent key={event.id}>
                    <div className="cardHeader">
                      <div className="headerInfos">
                        <p>{event.dateSelected}</p>
                        <h3>{event.dellName}</h3>
                      </div>
                      <div className="headerBtns">
                        <Button className='btn btn-secondary' onClick={() => currentEvent?.id === event.id ? setCurrentEvent(null) : setCurrentEvent(event)}>
                          {
                            currentEvent?.id === event.id ? 'Mostrar menos' : 'Mostrar mais'
                          }
                        </Button>
                      </div>
                    </div>
                    {
                      currentEvent?.id === event.id &&
                      <div className="cardContent">
                        <h5>Informações Dell:</h5>
                        <p><b>Nome representante:</b> {event.dellName}</p>
                        <p><b>E-mail representante:</b> {event.dellEmail}</p>
                        <p><b>Telefone representante:</b> {event.dellPhone}</p>
                        <p><b>Empresa convidada:</b> {event.companyInvited}</p>
                        <p><b>Data evento:</b> {event.dateSelected}</p>
                        <p><b>Tipo de evento:</b> {event.eventType}</p>
                        {/*  */}
                        <h5>Informações do Evento:</h5>
                        <p><b>Local do evento: </b> {event.cityInPerson}</p>
                        <p><b>Quantas pessoas no total participarão: </b> {event.quantityPersonsInPerson}</p>
                        <p><b>Considerar Coffee: </b> {event.coffeeIsPerson} | <b>Teremos almoço:</b> {event.lunchInPerson}</p>
                        <hr />
                        {/* <p><b>Local do almoço: </b> {event.lunchPlaceInPerson || 'Não selecionável'}</p> */}
                        {/* <p><b>Onde será o almoço: </b> {event.lunchFabricInPerson || 'Não selecionável'}</p> */}
                        {/* <p><b>Restaurante: </b> {event.restaurantInPerson || 'Não selecionável'}</p> */}
                        <p><b>Ponto de encontro: </b> {event.meetingPointInPerson || 'Não selecionável'}</p>
                        <p><b>Outro local de encontro: </b> {event.meetingPointOutherInPerson || 'Não selecionável'}</p>
                        {/* <p><b>Saladas: </b> {event.salad?.join(', ') || 'Não selecionável'}</p> */}
                        {/* <p><b>Prato Principal: </b> {event.mainCourse || 'Não selecionável'}</p> */}
                        {/* <p><b>Mignom pode ser: </b> {event.mainCoursePlus || 'Não selecionável'}</p> */}
                        {/* <p><b>Acompanhamentos: </b> {event.followUp?.join(', ') || 'Não selecionável'}</p> */}
                        {/* <p><b>Doces: </b> {event.candy || 'Não selecionável'}</p> */}
                        {/* <p><b>Frutas: </b> {event.fruit || 'Não selecionável'}</p> */}
                        {/* <p><b>Bebidas: </b> {event.drink || 'Não selecionável'}</p> */}
                        <hr />
                        <p><b>Sugestões para Door Opener: </b> {event.doorOpenerSugestion || 'Sem sugestões'}</p>
                        <div className="cardFooter">
                          <FormGroup>
                            <FormLabel><b>Status do evento:</b> ({event.status})</FormLabel>
                            <InputGroup>
                              <Form.Select
                                value={currentStatus}
                                onChange={e => setCurrentStatus(e.target.value)}
                              >
                                <option>Escolha um status</option>
                                {
                                  ['Em Aberto', 'Aceito', 'Finalizado', 'Arquivado'].map(item => (
                                    <option key={item} value={item}>{item}</option>
                                  ))
                                }
                              </Form.Select>
                              <Button
                                disabled={loading}
                                onClick={() => handleUpdateEventInPersonStatus(event)}
                              >
                                {loading ? 'Aguarde...' : 'Atualizar Status'}
                              </Button>
                            </InputGroup>
                          </FormGroup>
                        </div>
                      </div>
                    }
                  </CardEvent>
                )

              })
            }

          </div>
        </Modal>
      }
      <Container>
        <div className="calendarHeader">
          <button onClick={handlePrevMonth}>Anterior</button>
          <h3>{format(currentMonth, 'MMMM yyyy', { locale: ptBR })}</h3>
          <button onClick={handleNextMonth}>Próximo</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Dom</th>
              <th>Seg</th>
              <th>Ter</th>
              <th>Qua</th>
              <th>Qui</th>
              <th>Sex</th>
              <th>Sáb</th>
            </tr>
          </thead>
          <tbody>
            {calendar.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((date, index) => (
                  <td
                    key={index}
                    onClick={() => handleDateClick(date)}
                    className={`${isDatePresentInDatabase(date) ? 'data-presente-db' : ''
                      } ${isSameDay(date, today) ? 'dia-atual' : ''
                      }`}
                    data-present-in-db={isDatePresentInDatabase(date)}
                    data-is-today={isSameDay(date, today)}
                  >
                    {format(date, 'dd')}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    </>
  );

}
