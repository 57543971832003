import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  padding: 0 4%;

  #areaCards {
    max-width: 780px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
    margin: 0 auto;
  }

  .cardStyle {
    max-width: 250px;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 15px #ddd;
    border: none;

    button {
      width: 100%;
    }
  }

  .content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
  }
`;
