import { styled } from 'styled-components';

export const Container = styled.div`
  max-width: 780px;
  width: 100%;
  background: #fff;
  box-shadow: 1px 1px 15px #ddd;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 30px 0;

  .calendarHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    h3 {
      font-size: 1.1rem;
      font-weight: bold;
      color: #2c2c2c;
      text-transform: capitalize;
    }

    button {
      background: none;
      border: none;
      font-size: 0.75rem;
      font-weight: bold;
      color: #0d6efd;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
  }

  /* Estilos do cabeçalho do calendário */
  thead {
    background-color: #f0f0f0;
  }

  th {
    font-weight: bold;
  }

  /* Estilos das células do calendário */
  tbody td {
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }

  /* Estilos dos dias do calendário */
  td {
    padding: 5px;
  }

  /* Estilo para dias do mês anterior e posterior */
  td:not(:nth-child(n + 8)):not(:nth-child(-n + 1)) {
    color: #000;
  }

  /* Estilo para dias que estão no banco de dados */
  td[data-present-in-db='true'] {
    background-color: #0d6efd;
    color: #fff !important;
  }

  /* Estilos para realçar o dia atual */
  td[data-is-today='true'] {
    background-color: #ffe6b3;
    font-weight: bold;
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;

  button.btnClose {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-weight: bold;
    position: fixed;
    top: 10px;
    right: 10px;
    border: none;
  }

  .modalContent {
    max-width: 720px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 30px;
    background: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
`;
