import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';
import * as xlsx from 'xlsx';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import {
  HeaderEvent,
  EventsArea,
  CardEvent,
  GraphicArea,
  AllColumns,
} from './OnlineEventStyles';

import firebase from '../../services/firebaseConnect';
import { OnlineEventData } from '../Home';
import { toast } from 'react-hot-toast';
import {
  CalendarDaysIcon,
  ComputerDesktopIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

Chart.register(CategoryScale);

interface PageProps {
  handleClearSelected: () => void;
}

export default function OnlineEvent({ handleClearSelected }: PageProps) {
  const [loading, setLoading] = useState(false);
  const [dateString, setDateString] = useState('');
  const [allEvents, setAllEvents] = useState<OnlineEventData[] | []>([]);
  const [currentEvent, setCurrentEvent] = useState<OnlineEventData | null>(
    null
  );
  //
  // DELL INFORMATION
  //
  const [accountId, setAccountId] = useState('');
  const [outherDates, setOutherDates] = useState('');
  //
  const [dellName, setDellName] = useState('');
  const [dellEmail, setDellEmail] = useState('');
  const [dellPhone, setDellPhone] = useState('');
  const [companyInvited, setCompanyInvited] = useState('');
  const [eventType, setEventType] = useState('');
  const [totalParticipents, setTotalParticipants] = useState(0);
  const [totalConvidados, setTotalConvidados] = useState(0);
  const [outhersInfos, setOuthersInfos] = useState('');
  const [sugestions, setSugestions] = useState('');
  // EVENT INFORMATION

  //
  const [graphicInfos, setGraphicInfos] = useState<any[] | []>([]);
  //
  const [currentStatus, setCurrentStatus] = useState('');
  const [filterSelected, setFilterSelected] = useState('');

  const resetValuesForm = () => {
    setAccountId('');
    setOutherDates('');
    setDellName('');
    setDellEmail('');
    setDellPhone('');
    setCompanyInvited('');
    setEventType('');
    setTotalParticipants(0);
    setTotalConvidados(0);
    setOuthersInfos('');
    setSugestions('');
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const filteredListing = useMemo(() => {
    return allEvents?.filter((item) =>
      filterSelected
        ? item.status.toLowerCase().includes(filterSelected.toLowerCase())
        : true
    );
  }, [allEvents, filterSelected]);

  useEffect(() => {
    const filteredEvents = allEvents.filter((event) => event.status);

    if (filteredEvents.length === 0) {
      setGraphicInfos([]);
      return;
    }

    const currentInfos = filteredEvents.reduce((acc, event) => {
      //@ts-ignore
      const existingInfo = acc.find((info) => info.label === event.status);
      if (existingInfo) {
        //@ts-ignore
        existingInfo.data += 1;
      } else {
        //@ts-ignore
        acc.push({ label: event.status, data: 1 });
      }
      return acc;
    }, []);

    setGraphicInfos(currentInfos);
  }, [allEvents]);

  const getAllEvents = () => {
    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('online_events')
        .get()
        .then((snapshot) => {
          const arrEvents = [] as OnlineEventData[];

          snapshot.forEach((document) => {
            const newEvent = {
              id: document.id,
              accountId: document.data().accountId,
              outherDates: document.data().outherDates,
              dellName: document.data().dellName,
              dellEmail: document.data().dellEmail,
              dellPhone: document.data().dellPhone,
              companyInvited: document.data().companyInvited,
              dateSelected: document.data().dateSelected,
              eventType: document.data().eventType,
              totalPersonsOnline: document.data().totalPersonsOnline,
              totalDellPersonsOnline: document.data().totalDellPersonsOnline,
              outhersInfos: document.data().outhersInfos,
              doorOpenerSugestion: document.data().doorOpenerSugestion,
              createdAt: document.data().createdAt,
              status: document.data().status,
            } as OnlineEventData;

            arrEvents.push(newEvent);
          });

          setAllEvents(arrEvents);
        });
    } catch (error) {
      console.log('ERROR ONLINE: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatedEventDellInfo = (event: OnlineEventData) => {
    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('online_events')
        .doc(event.id)
        .update({
          accountId: accountId || event.accountId,
          outherDates: outherDates || event.outherDates,
          dellName: dellName || event.dellName,
          dellEmail: dellEmail || event.dellEmail,
          dellPhone: dellPhone || event.dellPhone,
          companyInvited: companyInvited || event.companyInvited,
          eventType: eventType || event.eventType,
          totalPersonsOnline: totalParticipents || event.totalPersonsOnline,
          totalDellPersonsOnline:
            totalConvidados || event.totalDellPersonsOnline,
          doorOpenerSugestion: sugestions || event.doorOpenerSugestion,
          outhersInfos: outhersInfos || event.outhersInfos,
        })
        .then(async () => {
          const filtered = await allEvents.filter(
            (item) => item.id !== event.id
          );
          //@ts-ignore
          event.accountId = accountId || event.accountId;
          //@ts-ignore
          event.outherDates = outherDates || event.outherDates;
          //@ts-ignore
          event.dellName = dellName || event.dellName;
          event.dellEmail = dellEmail || event.dellEmail;
          event.dellPhone = dellPhone || event.dellPhone;
          event.companyInvited = companyInvited || event.companyInvited;
          event.eventType = eventType || event.eventType;
          event.totalPersonsOnline =
            totalParticipents || event.totalPersonsOnline;
          event.totalDellPersonsOnline =
            totalConvidados || event.totalDellPersonsOnline;
          event.doorOpenerSugestion = dellEmail || event.dellName;
          // @ts-ignore
          event.outhersInfos = outhersInfos || event.outhersInfos;
          //
          setAllEvents([event, ...filtered]);
          toast.success('Informações atualizadas com sucesso!');
          setCurrentStatus('');
          resetValuesForm();
        });
    } catch (error) {
      console.log('ERRO DATA: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEventDate = (event: OnlineEventData) => {
    if (!dateString) return;

    if (dateString === event.dateSelected) {
      toast.error('Este evento já está com essa data definida!');
      return;
    }

    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('online_events')
        .doc(event.id)
        .update({
          dateSelected: dateString,
        })
        .then(async () => {
          const filtered = await allEvents.filter(
            (item) => item.id !== event.id
          );
          //@ts-ignore
          event.dateSelected = dateString;
          setAllEvents([event, ...filtered]);
          toast.success('Data atualizada com sucesso!');
          setCurrentStatus('');
        });
    } catch (error) {
      console.log('ERRO DATA: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEventStatus = (event: OnlineEventData) => {
    if (!currentStatus) return;

    if (currentStatus === event.status) {
      toast.error('Este evento já está com esse status definido!');
      return;
    }

    setLoading(true);
    try {
      firebase
        .firestore()
        .collection('online_events')
        .doc(event.id)
        .update({
          status: currentStatus,
        })
        .then(async () => {
          const filtered = await allEvents.filter(
            (item) => item.id !== event.id
          );
          //@ts-ignore
          event.status = currentStatus;
          setAllEvents([event, ...filtered]);
          toast.success('Status atualizado com sucesso!');
          setCurrentStatus('');
        });
    } catch (error) {
      console.log('ERRO STATUS: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAllExportXlsx = async (data: OnlineEventData[]) => {
    const newArr = await data.map((item) => {
      return {
        id: item.id,
        accountId: item.accountId,
        nome_solicitante_dell: item.dellName,
        email_solicitante_dell: item.dellEmail,
        telefone_solicitante_dell: item.dellPhone,
        empresa_convidada: item.companyInvited,
        data_selecionada: item.dateSelected,
        outras_datas: item.outherDates,
        tipo_evento: item.eventType,
        quantidade_pessoas: item.totalPersonsOnline,
        quantidade_pessoas_dell: item.totalDellPersonsOnline,
        outras_definicoes: item.outhersInfos,
        observacao_sugestao_door_opener: item.doorOpenerSugestion,
        createdAt: item.createdAt,
        status: item.status,
      };
    });

    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(newArr);

    xlsx.utils.book_append_sheet(wb, ws, 'Online_eventos');
    xlsx.writeFile(wb, 'Online_eventos.xlsx');
  };

  const getDiffDaysJS = (value: string) => {
    const currentDate = new Date();
    const providedDate = new Date(value);
    const differenceInMilliseconds =
      providedDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return differenceInDays < 5;
  };

  const getDiffDays = (value: any) => {
    const date = new Date();
    const day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const todayDate = `${date.getFullYear()}-${month}/${day}`;

    console.log(new Date(todayDate));

    // @ts-ignore
    const diffInMs = new Date(todayDate) - new Date(value);

    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // @ts-ignore
    return Math.abs(parseInt(diffInDays));
  };

  return (
    <>
      <HeaderEvent>
        <h5>Eventos Online</h5>
        <div id="areaHeaderBtns">
          {/*  */}
          <Form.Select
            value={filterSelected}
            onChange={(e) => setFilterSelected(e.target.value)}
          >
            <option value="">Mostrar Tudo</option>
            {['Em Aberto', 'Aceito', 'Finalizado', 'Arquivado'].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
          {/*  */}
          <Button
            className="btn btn-success"
            onClick={() => {
              filteredListing.length > 0
                ? handleAllExportXlsx(filteredListing)
                : toast.error(
                    'Ainda não há eventos como esse filtro para exportar!'
                  );
            }}
          >
            Exportar
          </Button>
          {/*  */}
          <Button
            className="btn btn-secondary"
            onClick={() => handleClearSelected()}
          >
            Voltar
          </Button>
        </div>
      </HeaderEvent>

      <AllColumns>
        <EventsArea>
          {allEvents.length > 0 &&
            filteredListing.map((event) => (
              <CardEvent key={event.id}>
                <div className="cardHeader">
                  <div className="headerInfos">
                    <p>{event.dateSelected}</p>
                    <h3>{event.dellName}</h3>
                  </div>
                  <div className="headerBtns">
                    <Button
                      className="btn btn-secondary"
                      onClick={() => {
                        if (currentEvent?.id === event.id) {
                          setCurrentEvent(null);
                          resetValuesForm();
                          return;
                        }

                        setCurrentEvent(event);
                        resetValuesForm();
                      }}
                    >
                      {currentEvent?.id === event.id
                        ? 'Mostrar menos'
                        : 'Mostrar mais'}
                    </Button>
                  </div>
                </div>
                {currentEvent?.id === event.id && (
                  <div className="cardContent">
                    <h5>Informações Dell:</h5>
                    <p>
                      <b>Account ID:</b> {event.accountId}
                    </p>
                    <p>
                      <b>Nome representante:</b> {event.dellName}
                    </p>
                    {/* <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Nome completo"
                        value={dellName}
                        onChange={(e) => setDellName(e.target.value)}
                      />
                    </InputGroup> */}
                    <p>
                      <b>E-mail representante:</b> {event.dellEmail}
                    </p>
                    {/* <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="E-mail representante"
                        value={dellEmail}
                        onChange={(e) => setDellEmail(e.target.value)}
                      />
                    </InputGroup> */}
                    <p>
                      <b>Telefone representante:</b> {event.dellPhone}
                    </p>
                    {/* <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Telefone do representante"
                        value={dellPhone}
                        onChange={(e) => setDellPhone(e.target.value)}
                      />
                    </InputGroup> */}
                    <p>
                      <b>Empresa convidada:</b> {event.companyInvited}
                    </p>
                    {/* <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Empresa convidada"
                        value={companyInvited}
                        onChange={(e) => setCompanyInvited(e.target.value)}
                      />
                    </InputGroup> */}
                    <p>
                      <b>Data evento:</b> {event.dateSelected}
                    </p>
                    <p>
                      <b>Outras datas:</b>{' '}
                      {event.outherDates || 'Não preenchido'}
                    </p>
                    <p>
                      <b>Tipo de evento:</b> {event.eventType}
                    </p>
                    {/* <InputGroup className="my-2">
                      <InputGroup.Text>
                        Evento{' '}
                        {!eventType ? (
                          <QuestionMarkCircleIcon style={{ marginLeft: 3 }} />
                        ) : eventType === 'Online' ? (
                          <ComputerDesktopIcon style={{ marginLeft: 3 }} />
                        ) : (
                          <TruckIcon style={{ marginLeft: 3 }} />
                        )}
                      </InputGroup.Text>
                      {['Online', 'Presencial'].map((item) => (
                        <Form.Check
                          type="radio"
                          name="typeEvent"
                          label={item}
                          id={item}
                          key={item}
                          onChange={() => setEventType(item)}
                          style={{ marginTop: 5, marginLeft: 10 }}
                        />
                      ))}
                    </InputGroup> */}
                    {/*  */}
                    <h5>Informações do Evento:</h5>
                    <p>
                      <b>Total de participantes: </b> {event.totalPersonsOnline}
                    </p>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="Total de participantes"
                        value={totalParticipents <= 0 ? '' : totalParticipents}
                        onChange={(e) =>
                          setTotalParticipants(parseInt(e.target.value))
                        }
                      />
                    </InputGroup>
                    <p>
                      <b>Total de convidados Dell: </b>{' '}
                      {event.totalDellPersonsOnline}
                    </p>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="Total de convidados Dell"
                        value={totalConvidados <= 0 ? '' : totalConvidados}
                        onChange={(e) =>
                          setTotalConvidados(parseInt(e.target.value))
                        }
                      />
                    </InputGroup>
                    <hr />
                    <p>
                      <b>Outras definições: </b>{' '}
                      {event.outhersInfos || 'Sem sugestões'}
                    </p>
                    <FormGroup>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Outras definições..."
                        value={outhersInfos}
                        onChange={(e) => setOuthersInfos(e.target.value)}
                      />
                    </FormGroup>
                    <hr />
                    <p>
                      <b>Sugestões para Dell Experience: </b>{' '}
                      {event.doorOpenerSugestion || 'Sem sugestões'}
                    </p>
                    <FormGroup>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Sugestões..."
                        value={sugestions}
                        onChange={(e) => setSugestions(e.target.value)}
                      />
                    </FormGroup>
                    {/*  */}
                    <Button
                      disabled={loading}
                      className="my-2"
                      onClick={() => handleUpdatedEventDellInfo(event)}
                    >
                      {loading ? 'Aguarde...' : 'Atualizar informações gerais'}
                    </Button>
                    {/* CHANGE DATE */}
                    <div className="cardFooter">
                      <FormGroup>
                        <FormLabel>
                          <b>Data atual:</b> ({event.dateSelected})
                        </FormLabel>
                        <InputGroup>
                          <InputGroup.Text>
                            <CalendarDaysIcon />
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            placeholder="Selecione uma data"
                            onChange={(e) => {
                              // if (getDiffDaysJS(e.target.value)) {
                              //   toast.error(
                              //     'O evento deve ter no mínimo 5 dias de antecedência'
                              //   );
                              //   return;
                              // }
                              const numDate = e.target.value.split('-');
                              const converted = `${numDate[2]}/${numDate[1]}/${numDate[0]}`;
                              setDateString(converted);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            width: '100%',
                            padding: 5,
                            borderRadius: 3,
                            background: '#009bbb',
                            fontSize: 12,
                            fontWeight: 'bold',
                            marginTop: 5,
                            marginBottom: 5,
                            color: '#fff',
                          }}
                        >
                          Data selecionada: {dateString}
                        </p>
                        <Button
                          disabled={loading}
                          onClick={() => handleUpdateEventDate(event)}
                        >
                          {loading ? 'Aguarde...' : 'Atualizar Data'}
                        </Button>
                      </FormGroup>
                    </div>
                    {/* CHANGE STATUS */}
                    <div className="cardFooter">
                      <FormGroup>
                        <FormLabel>
                          <b>Status do evento:</b> ({event.status})
                        </FormLabel>
                        <InputGroup>
                          <Form.Select
                            value={currentStatus}
                            onChange={(e) => setCurrentStatus(e.target.value)}
                          >
                            <option>Escolha um status</option>
                            {[
                              'Em Aberto',
                              'Aceito',
                              'Finalizado',
                              'Arquivado',
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <Button
                            disabled={loading}
                            onClick={() => handleUpdateEventStatus(event)}
                          >
                            {loading ? 'Aguarde...' : 'Atualizar Status'}
                          </Button>
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                )}
              </CardEvent>
            ))}
        </EventsArea>
        {allEvents.length > 0 && (
          <GraphicArea>
            <h3>Total de eventos online: ({allEvents.length})</h3>
            <Doughnut
              data={{
                labels: graphicInfos.map((d) => d.label),
                datasets: [
                  {
                    data: graphicInfos.map((d) => d.data),
                    backgroundColor: [
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#FF8A2A',
                      '#4BC0C0',
                    ],
                    hoverBackgroundColor: [
                      '#FF6384',
                      '#36A2EB',
                      '#FFCE56',
                      '#FF8A2A',
                      '#4BC0C0',
                    ],
                  },
                ],
              }}
            />
          </GraphicArea>
        )}
      </AllColumns>
    </>
  );
}
