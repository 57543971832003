import styled from 'styled-components';

export const HeaderEvent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;

  h5 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  #areaHeaderBtns {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const AllColumns = styled.div`
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px 0;
`;

export const EventsArea = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

export const GraphicArea = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;

  h3 {
    font-size: 1.3rem;
  }
`;

export const CardEvent = styled.div`
  max-width: 700px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;

  .cardHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eee;
    padding: 10px;
    border-radius: 10px;

    .headerInfos {
      p {
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 0px;
      }
      h3 {
        font-size: 1.1rem;
      }
    }

    .headerBtns {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .cardContent {
    padding: 10px;
    background: #fff;
    box-shadow: 1px 1px 15px #eee;

    h5 {
      margin: 20px 0 10px 0;
    }

    p {
      margin-bottom: 0px;
    }

    .cardFooter {
      border-top: 2px solid #eee;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
`;
